import React  from 'react'
import './About.css'
import { Player } from "@lottiefiles/react-lottie-player";
import { Container, Row, Col} from 'react-bootstrap';
import codecartelAbout from "../Assets/About/CODECARTEL.svg";
import caricatureSecond from "../Assets/About/caricatureSecond.svg";
import mobileLineD from "../Assets/About/mobileLineD.svg";
import connection from "../Assets/About/creative/connection.svg";
import collaboration from "../Assets/About/creative/collaboration.svg";
import innovation from "../Assets/About/creative/innovation.svg";
import experience from "../Assets/About/creative/experience.svg";
import aboutSecond from "../Assets/About/aboutSecond.json";
import aboutMain from "../Assets/About/aboutMain.json";
import teamJoin from "../Assets/About/teamJoin.json";
import aboutThird from "../Assets/About/aboutThird.json";
import utkarsh from "../Assets/About/team/utkarsh.svg";
import chetan from "../Assets/About/team/chetan.svg";
import naman from "../Assets/About/team/naman.svg";
import yash from "../Assets/About/team/yash.svg";
import swapnil from "../Assets/About/team/swapnil.svg";

function About() {

    return (
        <div>
           <section className='aboutDiv'>
               <Container>
                <div>
                    <Player className="aboutAdjustAnimate"
                    autoplay
                    loop
                    src={aboutMain}
                    ></Player>
                </div>
                   <div className='spaceTopA'>
                      <img data-aos="fade"
                        data-aos-duration="2000" src={codecartelAbout} className='aboutHeader' alt='codecartel about head'></img>
                      <p data-aos="fade-up"
                        data-aos-duration="3000" className='aboutMainHead'>Strategy, Design & Development Across All Platform</p>
                   </div>
                   </Container>
                   <hr className='styleLine'></hr>
                   <Container>
                       <div className='secondFoldA'>
                          <Row>
                            <Col sm={12} xs={12} md={5} lg={5} xl={5}>
                                <h4 data-aos="fade"
                        data-aos-duration="2000"  className='leftHeadA'>BRINGING DESIGN <span className='colorHighlight'>TO LIFE</span></h4>
                            </Col>
                            <Col sm={12} xs={12} md={7} lg={7} xl={7}>
                                <p className='paraRightA'>We solve consumer and business problems with end-to-end solutions that flex to meet the needs of today’s ever-changing digital landscape. Through design and excellent user interface, we leave no stones unturned to provide impeccable digital experiences to our clients.</p>
                            </Col>
                          </Row>
                       </div>
                       <img data-aos="fade"
                        data-aos-duration="3000" src={caricatureSecond} alt="caricature" className='caricatureStyle Dview'></img>
                        <img data-aos="fade"
                        data-aos-duration="3000" src={mobileLineD} alt="caricature" className='caricatureStyle Mview'></img>
                   </Container>
           </section>
           <section className='philosophyDiv'>
                <Container>
                    <h3 data-aos="fade"
                        data-aos-duration="2000" className='mainHeadLight centerMview'>our creative philosophy</h3>
                    <div className='cardsPhilosophy'>
                        <Row>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <div data-aos="fade-up"
                        data-aos-duration="3000" className='cardMainCreative p-Bottom'>
                                    <img src={connection} className='philosophyImage' alt='connection'></img>
                                    <p className='philosophyHead'>Connection</p>
                                    <p className='philosophyTxt'>Firing up that happy human chemistry</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <div data-aos="fade-up"
                        data-aos-duration="3000" className='cardMainCreative p-Bottom'>
                                    <img src={collaboration} className='philosophyImage customHeight' alt='Collaboration'></img>
                                    <p className='philosophyHead'>Collaboration</p>
                                    <p className='philosophyTxt'>Sharing inspiration, talents, and ideas</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <div data-aos="fade-up"
                        data-aos-duration="3000" className='cardMainCreative p-Bottom'>
                                    <img src={innovation} className='philosophyImage customImgHeight' alt='Innovation'></img>
                                    <p className='philosophyHead'>Innovation</p>
                                    <p className='philosophyTxt'>Opening the mind to see from new perspectives</p>
                                </div>
                            </Col>
                            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                                <div data-aos="fade-up"
                        data-aos-duration="3000" className='cardMainCreative'>
                                    <img src={experience} className='philosophyImage customHeightLast' alt='Experience'></img>
                                    <p className='philosophyHead'>Experience</p>
                                    <p className='philosophyTxt'>Taking risks, staying curious, and having the courage to learn</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
           </section>
           <section className='aboutParaOneDiv'>
               <Container>
                    <Row>
                        <Col className='Mview' sm={12} xs={12} md={6} lg={6} xl={6}>
                            <div data-aos="fade" data-aos-duration="2000" className='mStyleAnimateS'>
                                <Player id="firstLottie" className="paraOneAnimate" autoplay loop src={aboutSecond}></Player>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <div data-aos="fade" data-aos-duration="2000" className=''>
                                <p className='paraOneHead'>We help businesses grow by turning ideas into engaging digital products.</p>
                            </div>
                        </Col>
                        <Col className='Dview' sm={12} xs={12} md={6} lg={6} xl={6}>
                            <div data-aos="fade" data-aos-duration="2000" className=''>
                                <Player id="firstLottie" className="paraOneAnimate" autoplay loop src={aboutSecond}></Player>
                            </div>
                        </Col>
                    </Row>
               </Container>
           </section>
           <section className='spacePadding'>
                <Container>
                <Row>
                    <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                        <div data-aos="fade" data-aos-duration="2000" className='alignLeftanimate mStyleAnimateS'>
                            <Player className="paraOneAnimate" autoplay loop src={aboutThird}></Player>
                        </div>
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                        <div data-aos="fade"
                        data-aos-duration="2000" className=''>
                            <p className='paraTwoHead'>Our creative intelligence combined with technical expertise keeps business moving online.</p>
                        </div>
                    </Col>
                </Row>
               </Container>
            </section>
           <section className='teamDiv'>
               <Container>
                   <div className='spaceBottomTeam'>
                    <h1 data-aos="fade"
                        data-aos-duration="2000"  className="mainHeadLight alignCentertxt">Our Cartel</h1>
                            <p data-aos="fade"
                        data-aos-duration="2000"  className="subheadSecond alignCentertxt">
                            A madhouse of designers, explorers, thinkers, and tinkers too! Here, we design for the most probable future in the most creative way.
                            </p>
                   </div>
                   <Row className='pBTeam'>
                       <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                           <div data-aos="fade-up"
                        data-aos-duration="3000"  className='leftTeamCard'>
                                <img src={utkarsh} className='teamCardStyle' alt='utkarsh agrawal'></img>
                                <p className='nameTeam'>Utkarsh</p>
                                <p className='designation'>Chief Executive Officer</p>
                           </div>
                       </Col>
                       <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                           <div data-aos="fade-up"
                        data-aos-duration="3000" className='leftTeamCard'>
                                <img src={chetan} className='teamCardStyle' alt='chetan kumar'></img>
                                <p className='nameTeam'>Chetan</p>
                                <p className='designation'>Co-Founder & Creative Director</p>
                           </div>
                       </Col>
                       {/* <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                           <div data-aos="fade-up"
                        data-aos-duration="3000" className='leftTeamCard'>
                                <img src={naman} className='teamCardStyle' alt='naman agrawal'></img>
                                <p className='nameTeam'>Naman</p>
                                <p className='designation'>Co-Founder & CTO</p>
                           </div>
                       </Col> */}
                       <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                           <div data-aos="fade-up"
                        data-aos-duration="3000" className='leftTeamCard'>
                                <img src={swapnil} className='teamCardStyle' alt='swapnil'></img>
                                <p className='nameTeam'>Swapnil</p>
                                <p className='designation'>Full Stack Engineer</p>
                           </div>
                       </Col>
                   {/* </Row>
                   <Row> */}
                       <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                           <div data-aos="fade-up"
                        data-aos-duration="3000" className='leftTeamCard'>
                                <img src={yash} className='teamCardStyle' alt='yash bhayre'></img>
                                <p className='nameTeam'>Yash</p>
                                <p className='designation'>Full Stack Engineer</p>
                           </div>
                       </Col>
                       
                       
                       <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                       </Col>
                       <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                           <div data-aos="fade-up"
                        data-aos-duration="3000" className='cardJoin'>
                                <p className='joinHead'>Ready to create an impact on the world?</p>
                                <a href='/connect' className='teamJoin'>JOIN US!</a>
                           </div>
                       </Col>
                       <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                       </Col>
                   </Row>
               </Container>
           </section>
           <section className='ctaAboutLast'>
               <Container>
                   <Row>
                        <Col sm={12} xs={12} md={5} lg={5} xl={5}>
                            <Player className="" style={{ height: "500px" }} autoplay loop src={teamJoin}></Player>
                        </Col>
                        <Col sm={12} xs={12} md={7} lg={7} xl={7}>
                            <p className='paraTwoHeadLast'>Let’s design, transform, and launch your projects.</p>
                            <div className="spaceCtaTop">
                                <a href="/connect" className="whiteBtn">LET'S TALK</a>
                            </div>
                        </Col>
                   </Row>
               </Container>
           </section>
        </div>
    )
}

export default About